<template>
    <div>
        <div class="commen-hader">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <h2>MY JOBS</h2>
                    </div>
                </div>
            </div>
        </div>
        <Loader :isLoading="isLoading" />

        <div class="vacancies_area">
            <div class="container">
                <!--<h1><span><ImageItem src="images/heart.png"></span>Featured JOb Vacancies</h1>-->
                <div class="row">
                    <div class="col-md-6" v-for="job in jobs" :key="job.id">
                        <div class="vacancies_box">
                            <div class="row">
                                <div class="col-md-2">
                                    <div class="vacancies_box_image">
                                        <ImageItem :src="imgsrc + job.image" />
                                    </div>
                                </div>
                                <div class="col-md-10">
                                    <div class="vacancies_box_heading">
                                        <h5>
                                            <router-link
                                                :to="{
                                                    name: 'applyforjob',
                                                    params: { id: job.id },
                                                }"
                                                >{{ job.job_title }}</router-link
                                            >
                                            - <span>{{ job.company_name || "MEPC" }}</span>
                                        </h5>
                                    </div>
                                    <div v-if="active_deactive === 'Deactive'">Not Approved</div>
                                    <div class="vacancies_box_content">
                                        <span
                                            ><i class="fa fa-map-marker"></i> {{ job.state }}</span
                                        >
                                        <span><i class="fa fa-map-marker"></i> {{ job.city }}</span>
                                        <br />
                                        <span style="margin-left: 6px;"
                                            ><i class="fa fa-clock-o" aria-hidden="true"></i>
                                            {{ job.expiry_date }}</span
                                        >
                                        <span style=""
                                            ><i class="fa fa-heart-o" aria-hidden="true"></i>
                                            {{ job.job_type }}</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from "../_helper/http-constants"
import axios from "axios"
import scrollTop from "./utils/scrollTop"
import ImageItem from "./utils/ImageItem"
import request from "../apis/request"
import Loader from "./Loader.vue"

export default {
    name: "vacancies",

    components: {
        ImageItem,
        Loader,
    },
    data() {
        return {
            jobs: "",
            isLoading: false,
            //   imgsrc: 'http://103.212.120.205/~dev/public/admin_profile/',
            imgsrc: axios.defaults.uploadURL,
        }
    },

    mounted() {
        scrollTop()
        this.isLoading = true
        request
            .get("/my_job")
            .then((res) => {
                this.isLoading = false
                this.jobs = res.data
            })
            .catch((err) => {
                this.isLoading = false
            })
    },
}
</script>
